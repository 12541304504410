.log-viewer {
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f8f9fa;
}

.log-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background: #e9ecef;
  border-bottom: 1px solid #ddd;
  border-radius: 8px 8px 0 0;
}

.log-header h3 {
  margin: 0;
  color: #333;
}

.log-count {
  background: #6c757d;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.9em;
}

.log-container {
  height: 300px;
  width: 100%;
  position: relative;
  background: #fff;
}

/* Style for the AutoSizer container */
.log-container > div {
  height: 100%;
  width: 100%;
}

.log-entry {
  font-family: monospace;
  font-size: 0.9em;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.log-entry .timestamp {
  color: #666;
  min-width: 100px;
  flex-shrink: 0;
}

.log-entry .message {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.log-entry.info {
  background: #f8f9fa;
}

.log-entry.error {
  background: #fff5f5;
  color: #e53e3e;
}

.log-entry.success {
  background: #f0fff4;
  color: #38a169;
}

/* Alternating row colors */
.log-entry:nth-child(even) {
  background: #f8f9fa;
}

.log-entry:nth-child(odd) {
  background: #ffffff;
}

/* Hover effect */
.log-entry:hover {
  background: #e9ecef;
}

/* Scrollbar styles */
.log-container ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.log-container ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.log-container ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.log-container ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
