.response-display {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  max-width: 100%;
  margin: 0 auto;
}

.response-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.response-header h3 {
  margin: 0;
}

.response-content {
  line-height: 1.6;
  overflow-wrap: break-word;
}

.markdown-content {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.markdown-content p {
  margin: 0.5em 0;
}

.markdown-content code {
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: 'Consolas', 'Monaco', monospace;
}

.markdown-content pre {
  padding: 1em;
  border-radius: 5px;
  overflow-x: auto;
}

.markdown-content pre code {
  background-color: transparent;
  padding: 0;
}

.individual-responses {
  margin-top: 1rem;
}

.response-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.badges {
  display: flex;
  gap: 0.5rem;
}

.fallback-badge,
.truncated-badge,
.refusal-badge {
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
}

.fallback-badge {
  background-color: rgba(168, 230, 195, 0.2);
  color: #a8e6c3;
}

.truncated-badge {
  background-color: rgba(230, 213, 168, 0.2);
  color: #e6d5a8;
}

.refusal-badge {
  background-color: rgba(230, 168, 168, 0.2);
  color: #e6a8a8;
}

.error-message {
  color: #e6a8a8;
  background-color: rgba(74, 45, 45, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.refusal-message {
  color: #e6d5a8;
  background-color: rgba(74, 61, 45, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.copy-button {
  background: transparent;
  border: 1px solid;
  border-radius: 4px;
  padding: 0.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.copy-button:hover {
  opacity: 0.8;
}

.copy-button.copied {
  background-color: rgba(168, 230, 195, 0.2);
}

.query-summary {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;
}

.summary-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin: 1rem 0;
}

.model-stats {
  margin-top: 1rem;
}

.model-stat {
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.model-name {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.stat-details {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  font-size: 0.9rem;
}

.error-list {
  margin-top: 0.5rem;
}

.error-item {
  margin: 0.2rem 0;
  font-size: 0.9rem;
}

.model-count {
  margin-bottom: 1rem;
}

.response-group {
  margin-bottom: 2rem;
}

.response-group h3 {
  margin-bottom: 1rem;
}

/* Reformat Card Styles */
.reformat-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reformat-button {
  background: transparent;
  color: inherit;
  border: 1px solid;
  border-radius: 4px;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.reformat-button:hover {
  opacity: 0.8;
}

.reformat-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
