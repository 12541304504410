/* Existing styles */

/* Model Selector Styles */
.model-selector {
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.model-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.model-item {
  padding: 0.5rem;
  border-radius: 4px;
  background: #f5f5f5;
}

.model-item label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.model-name {
  font-weight: bold;
}

.model-description {
  font-size: 0.9rem;
  color: #666;
  margin-left: 0.5rem;
}

.model-selector-error {
  color: red;
  padding: 1rem;
  border: 1px solid red;
  border-radius: 4px;
  margin: 1rem 0;
}

.model-selector-loading {
  padding: 1rem;
  text-align: center;
  color: #666;
}
